@import "@/services/scss/variables.scss";
@import "@/services/scss/mixin.scss";

.title_wrap {
    padding-bottom: 10px;

    .title {
        @include flexBetween;
        span {
            font-size: 14px;
            font-weight: 600;
            @include itemsCenter;
        }
        a {
            color: $dark;
            text-decoration: none;
            // background-color: $orange;
            font-size: 13px;
            font-weight: 700;
            @include borderRadius;
            padding: 5px;
        }
    }
    span {
        font-size: 12px;
        font-weight: 500;
        display: block;
    }
    .text_primary {
        color: $primary;
    }
    .text_error {
        color: $error;
    }
}

.bottom_link {
    padding-bottom: 0;
    color: $dark;

    .bottom_link_btn {
        @include flexBetween;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        padding: 12px $cp;
        color: $dark;

        span {
            @include itemsCenter;

            svg {
                @include itemsCenter;
                // rotate: 180deg;
                width: 24px;
                height: 24px;
            }
        }
    }
}
