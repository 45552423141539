@import "@/services/scss/variables.scss";
@import "@/services/scss/mixin.scss";

.order_list_wrp {
    .order_list {
        padding: 0;

        .header {
            padding: 10px;
            @include itemsCenter;
            justify-content: space-between;
            border-bottom: 1px solid $grey200;
        }
        .content {
            padding: 10px;

            & > div {
                margin-top: 10px;
                &:first-child {
                    margin-top: 0;
                }
                @include flexBetween;
            }
        }
    }
}

.order_details_wrp {
    @include border;
    @include borderRadius;
    @include boxShadow2;

    .order_details {
        @include itemsCenter;
        padding: 10px;

        & > div {
            width: 100%;
        }

        .lines {
            max-width: 30px;

            @include flexColumn;
            align-items: center;
            // justify-content: center;
            // align-items: center;
        }
    }
    .flex_center {
        @include itemsCenter;
        flex-direction: column;
        border-top: 1px solid $grey200;
        padding-top: 10px;
    }
}
