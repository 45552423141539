@import "@/services/scss/variables.scss";
@import "@/services/scss/mixin.scss";

$loaderBackground: #eeeeee;
$loaderHighlight: #dedede;

.line_skeleton {
    background: linear-gradient(90deg, $loaderBackground 25%, $loaderHighlight 50%, $loaderBackground 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    border-radius: 8px;
}

.header_skeleton {
    margin-top: 10px;
    .top {
        @include flexBetween;
    }
}
.items_list {
    @include itemsCenter;
    margin-top: 10px;
}
.border {
    @include border;
    @include borderRadius;
}
.shadow {
    @include boxShadow2;
}

.items_center_col {
    @include itemsCenter;
    flex-direction: column;
}
.items_col {
    @include flexColumn;
}
.flex_start {
    align-items: flex-start;
}

.avatar_skeleton {
    background: linear-gradient(90deg, $loaderBackground 25%, $loaderHighlight 50%, $loaderBackground 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
}

.cover_image_skeleton {
    width: 300px;
    height: 200px;
    background: linear-gradient(
        90deg,
        var(--loader-background-color) 25%,
        var(--loader-highlight-color) 50%,
        var(--loader-background-color) 75%
    );
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    border-radius: 8px;
    margin-bottom: 20px;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

.product_list {
    @include itemsCenter;

    .content {
        width: 100%;
        margin-left: 10px;
        height: 100%;
        @include flexColumn;
        justify-content: center;
    }
}

.product_grid {
    .img_wrap {
        position: relative;
        img {
            opacity: 0;
            align-self: 1;
        }

        .img_skeleton {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
        }

        .top_off {
            position: absolute;
            top: 0;
            left: 10px;
            z-index: 1;
            @include borderRadius;
        }
    }
    .content {
        padding: 10px;

        .bottom {
            @include itemsCenter;
            justify-content: space-between;
        }

        .btm_cnt {
            @include flexColumn;
            width: 40%;
        }
    }
}

.max_width_product {
    margin-right: 10px;
    min-width: 135px;
    max-width: 135px;
    &:last-child {
        margin-right: 0;
    }
}

.review_list {
   padding-bottom: 16px;
   margin-top: 16px;
   border-bottom: 1px solid $ltGreyScale300;
    .content {
        width: 100%;
       
        height: 100%;
        @include flexColumn;
        justify-content: center;
    }
    .top {
        display: flex;
        width: 100%;
        
        height: 100%;
        justify-content: space-between;
    }
    .avatar {
        display: flex;
        gap: 10px;
    }
}