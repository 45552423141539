@import "@/services/scss/variables.scss";
@import "@/services/scss/mixin.scss";

.draggable_section {
    // padding: 10px 0 0 0;
    width: 100%;
    overflow: hidden;
    position: relative;
    // background-color: red;

    .draggable_container {
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            height: 0px;
            // background-color: rgba($color: $primary, $alpha: 0.2);
            // @include borderRadius;
        }

        // &::-webkit-scrollbar-thumb {
        //     background-color: rgba($color: $error, $alpha: 1);
        //     @include borderRadius;
        // }
        // @media only screen and (max-width: 568px) {
        //     &::-webkit-scrollbar {
        //         background-color: transparent;
        //     }

        //     &::-webkit-scrollbar-thumb {
        //         background-color: transparent;
        //     }
        // }
    }
    // .space {
    //     // padding-bottom: 40px;
    // }

    // .arrowContainer {
    //     position: absolute;
    //     // top: auto;
    //     bottom: 0;
    //     height: 100%;
    //     @include itemsCenter;

    //     button {
    //         background-color: transparent;
    //         border: none;
    //         @include itemsCenter;
    //         justify-content: center;
    //         padding: 0;
    //         width: 20px;
    //         height: 20px;
    //         background-color: rgba($color: $primary, $alpha: 1);
    //         border-radius: 100%;
    //         color: $white;

    //         svg {
    //             width: 20px;
    //             height: 20px;
    //             @include itemsCenter;
    //             justify-content: center;
    //         }
    //     }
    // }
    // .arrow_left {
    //     left: 0;
    //     z-index: 9;

    //     svg {
    //         rotate: 180deg;
    //     }
    // }
    // .arrow_right {
    //     right: 0;
    // }

    // @media only screen and (max-width: 768px) {
    //     .arrowContainer {
    //         display: none;
    //     }
    //     .space {
    //         padding: 0;
    //     }
    // }
}
.space_main {
    padding: 0;
}
