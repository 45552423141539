@import "@/services/scss/variables.scss";
@import "@/services/scss/mixin.scss";

.loader_wrp {
    @include itemsCenter;

    .loader {
        width: 35px;
        height: 35px;
        border: 5px solid rgba($color: $primary, $alpha: 0.5);
        border-bottom-color: $primary;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        margin: 0 auto;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.overlay_wrap {
    // .overlay_bg {
    //     background-color: black;
    // }
    width: 100vw;
    height: 100vh;
    max-width: 768px !important;

    display: flex;
    align-items: center;
    justify-content: center;

    .overlay_bg {
        width: 100vw;
        height: 100vh;
        max-width: 768px !important;

        position: absolute;
        background-color: rgba($color: $dark, $alpha: 0.3);
        top: 0;
        z-index: 9999;
    }

    .overlay {
        @include itemsCenter;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        background-color: $white;
        padding: 15px 8px;
        flex-direction: column;
        @include borderRadius;
        z-index: 9999;
        p {
            margin: 0;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 600;
            color: $greyD;
            margin-top: 10px;
        }

        .loader {
            width: 40px;
            height: 40px;
            border: 5px solid rgba($color: $greyD, $alpha: 0.5);
            border-bottom-color: $greyD;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
            margin: 0 auto;
        }

        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}

.overlay_wrap_fixed {
    position: fixed;
    width: 100vw !important;
    height: 100vh !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    max-width: 768px !important;

    .overlay_bg {
        background-color: rgba($color: $dark, $alpha: 0.3);
        top: 0;
        left: 0;
        z-index: 99999;
        width: 100vw;
        height: 100vh;
        max-width: 768px !important;
    }

    .overlay {
        @include borderRadius;
        @include itemsCenter;
        flex-direction: column;
        position: absolute;
        background-color: $white;
        padding: 15px 8px;
        z-index: 9999;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        p {
            margin: 0;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 600;
            color: $greyD;
            margin-top: 10px;
        }

        .loader {
            width: 40px;
            height: 40px;
            border: 5px solid rgba($color: $greyD, $alpha: 0.5);
            border-bottom-color: $greyD;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
            margin: 0 auto;
        }

        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}

.bg_white {
    .overlay_bg {
        background-color: $white;
    }
}
